html {
  font-size: 10px;
}
#root {
  overflow-x: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

html::-webkit-scrollbar {
  width: 10px;
}
html::-webkit-scrollbar-track {
  background-color: white;
}

html::-webkit-scrollbar-thumb {
  background-color: rgba(112, 115, 124, 0.22);
  border-radius: 4px;
}

html::-webkit-scrollbar-button {
  display: none;
}

div::-webkit-scrollbar {
  width: 10px;
}
div::-webkit-scrollbar-track {
  background-color: white;
}

div::-webkit-scrollbar-thumb {
  background-color: rgba(112, 115, 124, 0.22);
  border-radius: 4px;
}

div::-webkit-scrollbar-button {
  display: none;
}

.ReactModal__Content {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.ReactModal__Content::-webkit-scrollbar {
  display: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* .intro-img-fir-wrapper {
  &:hover {
    z-index: 2;
  }
}

.intro-img-fir {
  width: 100%;

  &:hover {
    width: 120%;
  }
} */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/** font area **/
@font-face {
  font-family: "Thin";
  src: url("./assets/font/Pretendard-Thin.otf");
  font-weight: 200;
}

@font-face {
  font-family: "Light";
  src: url("./assets/font/Pretendard-Light.otf");
  font-weight: 300;
}

@font-face {
  font-family: "ExtraLight";
  src: url("./assets/font/Pretendard-ExtraLight.otf");
}

@font-face {
  font-family: "Regular";
  src: url("./assets/font/Pretendard-Regular.otf");
  font-weight: 400;
}

@font-face {
  font-family: "Medium";
  src: url("./assets/font/Pretendard-Medium.otf");
  font-weight: 500;
}

@font-face {
  font-family: "SemiBold";
  src: url("./assets/font/Pretendard-SemiBold.otf");
  font-weight: 600;
}

@font-face {
  font-family: "Bold";
  src: url("./assets/font/Pretendard-Bold.otf");
  font-weight: 700;
}

@font-face {
  font-family: "ExtraBold";
  src: url("./assets/font/Pretendard-ExtraBold.otf");
  font-weight: 700;
}
